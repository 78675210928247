import { useState } from "react";
import { scaleOrdinal, scaleSequential } from "d3-scale";
import { interpolateRainbow } from "d3-scale-chromatic";

const powerSourcesValues = [
  "Propane",
  "None",
  "Natural",
  "Unknown",
  "Diesel",
  "Electric",
  "El/D",
];

const wellUsesValues = [
  "Commercial/Industrial",
  "Other",
  "Aquaculture",
  "Domestic",
  "Livestock",
  "Public with Spacing",
  "Public without Spacing",
  "Irrigation",
];

// const certAcresValues = [500, 250, 100, 10, 1, 0];

const wellStatusValues = ["Unused", "Abandoned", "Active", "Inactive"];

const cropsValues = [
  "Feed",
  "Unknown",
  "Corn/Milo",
  "Corn/Wheat/Feed",
  "Beans/Feed",
  "Grass",
  "Turnips",
  "Alf/Beans",
  "Corn/Feed",
  "Beans/Corn",
  "Beans/Wheat",
  "Sunflowers",
  "Feed/Wheat",
  "Beans/Milo",
  "Wheat/Feed/Milo",
  "Corn/Wheat",
  "Beans",
  "Not Used",
  "Wheat",
  "Corn",
  "Milo",
  "Alf/Corn",
  "Potatoes",
  "CREP",
  "Wheat/Beans",
  "Stubble",
  "Alf",
];

const colors = (specifier) => {
  var n = (specifier.length / 6) | 0,
    colors = new Array(n),
    i = 0;
  while (i < n) colors[i] = "#" + specifier.slice(i * 6, ++i * 6);
  return colors;
};

const colorsString20 =
  "1F77B4AEC7E8FF7F0EFFBB782CA02C98DF8AD62728FF98969467BDC5B0D58C564BC49C94E377C2F7B6D27F7F7FC7C7C7BCBD22DBDB8D17BECF9EDAE5";

const buildScale = (values) => {
  const scale =
    values.length <= 20
      ? scaleOrdinal(colors(colorsString20))
      : scaleSequential(interpolateRainbow);

  const degree = 100 / values.length / 100;

  return values.reduce((acc, val, i) => {
    acc.push([val]);

    if (values.length <= 20) {
      acc.push(scale(val));
    } else {
      acc.push(scale(i * degree));
    }

    return acc;
  }, []);
};

// const buildScaleNumbers = (values, get) => {
//   const scale = scaleOrdinal(schemePaired);
//   const expression = values.reduce((acc, val) => {
//     acc.push([">=", ["to-number", get], val]);
//     acc.push(scale(val));
//     return acc;
//   }, []);
//   console.log(expression);
//   return expression;
// };

const layerId = "mrnrd-wells-circle";
const styleValues = {
  default: {
    id: "default",
    layerId,
    layerFieldName: "",
    name: "Default",
    paint: {
      "circle-color": "#1e8dd2",
    },
  },
  powerSources: {
    id: "powerSources",
    layerId,
    layerFieldName: "powersource",
    name: "Power Source",
    paint: {
      "circle-color": [
        "match",
        ["get", "powersource"],
        ...buildScale(powerSourcesValues),
        "#000000",
      ],
    },
  },
  wellUses: {
    id: "wellUses",
    layerId,
    layerFieldName: "well_use",
    name: "Well Use",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "well_use"],
        ...buildScale(wellUsesValues),
        "#000000",
      ],
    },
  },
  wellStatus: {
    id: "wellStatus",
    layerId,
    layerFieldName: "well_status",
    name: "Well Status",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "well_status"],
        ...buildScale(wellStatusValues),
        "#000000",
      ],
    },
  },
  crops: {
    id: "crops",
    layerId,
    layerFieldName: "crop_name",
    name: "Crop",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "crop_name"],
        ...buildScale(cropsValues),
        "#000000",
      ],
    },
  },
  certAcres: {
    id: "certifiedAcres",
    layerId,
    layerFieldName: "cert_acres",
    name: "Certified Acres",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "case",
        // ...buildScaleNumbers(certAcresValues, ["get", "cert_acres"]),
        ["<", ["get", "cert_acres"], 1],
        "#FE0000",
        ["<", ["get", "cert_acres"], 10],
        "#FFFB00",
        ["<", ["get", "cert_acres"], 100],
        "#FFA500",
        ["<", ["get", "cert_acres"], 250],
        "#009A00",
        ["<", ["get", "cert_acres"], 500],
        "#0030FF",
        [">=", ["get", "cert_acres"], 500],
        "#800080",
        "#000000",
      ],
    },
  },
};

const useLayerStyles = ({ onLayerStyleChange }) => {
  const [activeStyle, setActiveStyle] = useState(styleValues.default);
  const styleOptions = Object.entries(styleValues).map(([key, value]) => ({
    display: value.name,
    value: key,
  }));

  const handleActiveStyle = (name) => {
    setActiveStyle(styleValues[name]);
    onLayerStyleChange(styleValues[name]);
  };

  return {
    activeStyle,
    handleActiveStyle,
    styleOptions,
    styleValues,
  };
};

export default useLayerStyles;
