export const BASEMAP_STYLES = [
  {
    style: "outdoors-v11",
    name: "Outdoors",
    url: "mapbox://styles/mapbox/outdoors-v11",
  },
  {
    style: "streets-v11",
    name: "Streets",
    url: "mapbox://styles/mapbox/streets-v11",
  },
  {
    style: "satellite-streets-v11",
    name: "Satellite",
    url: "mapbox://styles/mapbox/satellite-streets-v11",
  },
  {
    style: "light-v10",
    name: "Light",
    url: "mapbox://styles/mapbox/light-v10",
  },
  {
    style: "dark-v10",
    name: "Dark",
    url: "mapbox://styles/mapbox/dark-v10",
  },
];

export const DEFAULT_MAP_CENTER = [-100.7494, 40.51542];

export const INIT_MAP_CONFIG = {
  style: BASEMAP_STYLES[0].url,
  // center: DEFAULT_MAP_CENTER,
  // zoom: 8.6,
  preserveDrawingBuffer: true,
};

export const WELLS_LAYER_ID = "mrnrd-wells-circle";
export const WELLS_LABELS_LAYER_ID = "mrnrd-wells-symbol";
export const INIT_FILTER_VALUES = {
  powerSources: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "powersource",
    options: [],
    type: "multi-select",
    value: [],
  },
  wellUses: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "well_use",
    options: [],
    type: "multi-select",
    value: [],
  },
  wellStatus: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "well_status",
    options: [],
    type: "multi-select",
    value: [],
  },
  crops: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "crop_name",
    options: [],
    type: "multi-select",
    value: [],
  },
  isAlluvial: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "is_alluvial",
    type: "boolean",
    value: false,
  },
  isPermitted: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "is_permitted",
    type: "boolean",
    value: false,
  },
  isComingled: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "is_comingled",
    type: "boolean",
    value: false,
  },
  isPlattebasin: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "is_plattebasin",
    type: "boolean",
    value: false,
  },
  isNrdgps: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "is_nrdgps",
    type: "boolean",
    value: false,
  },
  hasProduction: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "has_production",
    type: "boolean",
    value: false,
  },
  hasTelemetry: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "has_telemetry",
    type: "boolean",
    value: false,
  },

  search: {
    layerId: WELLS_LAYER_ID,
    type: "select",
    value: "attributes_search",
  },
};

export const WELLS_SEARCH_OPTIONS = [
  {
    value: "attributes_search",
    label: "Attributes",
  },
  {
    value: "pools_search",
    label: "Pools",
  },
];
